import RetailProductItem from "@/components/retail-order/retail-product-item/index.vue";
import { getRetailState } from "@/constants/retail.js";

export default {
  name: "retial-product-list",
  components: { RetailProductItem },
  filters: {
    getRetailState(state) {
      return getRetailState(state);
    },
  },
  props: {
    order: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    //  已选商品件数
    sumCount() {
      return (this.order.productList || []).reduce((sum, p) => {
        return sum + p.quantity;
      }, 0);
    },
  },
  methods: {
    //  取消
    cancel() {
      this.$emit("cancel", this.order);
    },
    //  发货
    delivery() {
      this.$emit("delivery", this.order);
    },
    click() {
      this.$emit("click", this.order);
    },
  },
};
